/*	---------------------------------------------------------------
	PX to REM
	---------------------------------------------------------------
*/
/*	---------------------------------------------------------------
	Maps for styleguide and utility classes
	---------------------------------------------------------------
*/
/*	---------------------------------------------------------------
	PX to REM
	---------------------------------------------------------------
*/
/*	---------------------------------------------------------------
	Maps for styleguide and utility classes
	---------------------------------------------------------------
*/
/*	---------------------------------------------------------------
	Maps for styleguide and utility classes
	---------------------------------------------------------------
*/
/*	---------------------------------------------------------------
	Maps for styleguide and utility classes
	---------------------------------------------------------------
*/
.textbox .headline {
  margin-bottom: 1.25rem; }
  @media (min-width: 992px) {
    .textbox .headline {
      margin-bottom: 1.875rem; } }

@media (min-width: 992px) {
  .textbox .description.columns-2 {
    -moz-column-count: 2;
         column-count: 2;
    -moz-column-gap: 3.5rem;
         column-gap: 3.5rem; } }

@media (min-width: 992px) {
  .textbox .description.columns-3 {
    -moz-column-count: 3;
         column-count: 3;
    -moz-column-gap: 3.5rem;
         column-gap: 3.5rem; } }

.textbox .heading-btns {
  margin-top: 2.125rem; }

.textbox .text-center .heading-btns {
  justify-content: center; }

.textbox .text-left .heading-btns {
  justify-content: left; }

.textbox .text-right .heading-btns {
  justify-content: right; }

.textbox.light .eyebrow,
.textbox.light .headline,
.textbox.light .headline .headline-link,
.textbox.light .description {
  color: var(--color-white); }

.textbox.cheatsheet {
  border: 0.125rem solid var(--color-black);
  padding: 2.125rem 1.5625rem 2.375rem; }
  @media (min-width: 992px) {
    .textbox.cheatsheet {
      padding: 2.25rem 3.625rem; } }
  .textbox.cheatsheet .eyebrow {
    margin-bottom: 0.75rem; }
  .textbox.cheatsheet .headline {
    margin-bottom: 1.875rem; }
  .textbox.cheatsheet .description ul {
    margin-bottom: 0;
    padding-left: 1.875rem; }
    .textbox.cheatsheet .description ul li:not(:last-child) {
      margin-bottom: 1.25rem; }
